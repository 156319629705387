import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import QuestionsContainer from './components/QuestionsContainer';
import { QuestionAnswerContextProvider } from './components/QuestionAnswerContext';
import Spinner from '../common/Spinner';

const QuestionDetailsPage = (props) => {
    // store querystring params
    const [queryParams, setQueryParams] = useState(undefined);
    // store all categories
    const [categories, setCategories] = useState([]);
    // store loading state
    const [isLoading, setIsLoading] = useState(false);

    const searchParam = useLocation().search;

    // fetch and decrypt querystring params, get categories etc
    useEffect(() => {
        const api_url = process.env.REACT_APP_SERVER_URL;
        const getInitialData = async () => {
            try {
                setIsLoading(true);
                let promises = [];
                const payload = {
                    CustomerNo: new URLSearchParams(searchParam).get('c'),
                    Email: new URLSearchParams(searchParam).get('e'),
                    Currency: new URLSearchParams(searchParam).get('curr'),
                    ConfigID: new URLSearchParams(searchParam).get('cID') || new URLSearchParams(searchParam).get('cId') || new URLSearchParams(searchParam).get('cid'),
                    CloneID: new URLSearchParams(searchParam).get('cn'),
                    ProductId: new URLSearchParams(searchParam).get('p'),
                    Pricing: new URLSearchParams(searchParam).get('pr'),
                    CanAddToCart: new URLSearchParams(searchParam).get('a'),
                    Warehouse: new URLSearchParams(searchParam).get('wh')
                };

                //decrypt query params
                promises.push(
                    axios.post(`${api_url}Product/Decrypt`, payload, {
                        headers: { 'Content-Type': 'application/json' },
                    })
                );

                // load categories
                promises.push(axios.get(`${api_url}Product/GetCategory`));

                Promise.all(promises).then((responses) => {
                    // set decrypted query params
                    const queryParamData = responses[0].data;
                    const paramsData = {
                        ...queryParamData,
                        currency: queryParamData.currency ?? 'USD',
                        pricing: queryParamData.pricing ?? '1,1,1', // default 'See all pricing {MSRP,Wholesale,MyPrice}'
                        canAddToCart: queryParamData.canAddToCart?.toLowerCase() === "false" ? false : true,
                        warehouse:queryParamData.warehouse ?? 'DANA1',
                        isG: queryParamData.isGuest
                    };
                    setQueryParams(paramsData);
                    // set categories
                    setCategories(responses[1].data);
                    
                    setIsLoading(false);
                });
            } catch (error) {
                console.log('error initializing the application', error);
                setIsLoading(false);
            }
        };
        getInitialData();
    }, [searchParam]);

    return (
        <>
            {isLoading && <Spinner />}
            {queryParams && (
                <QuestionAnswerContextProvider>
                    <QuestionsContainer
                        queryParams={queryParams}
                        categories={categories}
                    />
                </QuestionAnswerContextProvider>
            )}
        </>
    );
};

export default QuestionDetailsPage;
