import React from "react";
import { CardTitle } from 'reactstrap';

const TitleHeader = (props) =>{

    const {show} = props;

return (
    
        show ?  
            <CardTitle
                    tag='h1'
                    style={{
                        backgroundColor: '#ffffff',
                        minHeight: '10vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        padding: '20px 0px 10px',
                    }}
                >
            <img src="https://my.sonance.com/UserFiles/Images/SON_header-logo.png" border="0" alt="MySonance" />
            </CardTitle>
        : <>    </>
     
   
 
)

}


export default TitleHeader;

