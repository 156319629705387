import React from 'react';
import { Button } from 'reactstrap';

const TableBody = (props) => {
    const rows = props.sessionData.map((row, index) => {
        return (
            <tr key={index}>
                <td onClick={(e) => props.editRecord(row)}>
                    <u style={{ color: 'blue', cursor: 'pointer' }}>
                        {row.baseProduct}
                    </u>
                </td>
                <td>{row.projectName}</td>
                <td>{row.title}</td>
                <td>{row.qty}</td>
                <td>{row.price === 'TBD' ? row.price : `$${row.price}`}</td>
               
                <td>
                    <input
                        type='text'
                        defaultValue={row.status}
                        onBlur={(e) => {
                            props.updateStatus(row, e.target.value);
                        }}
                    />
                </td>
                <td>
                    <Button
                        title='Edit'
                        className='btn-custom bi bi-pencil'
                        onClick={(e) => props.editRecord(row)}
                    >
                        <span id='boot-icon'></span> Edit
                    </Button>
                </td>
                <td>
                    <Button
                        title='Clone'
                        className='btn-custom bi bi-pencil'
                        onClick={(e) => props.cloneRecord(row)}
                    >
                        <span id='boot-icon'></span> Clone
                    </Button>
                </td>
            </tr>
        );
    });

    return <tbody>{rows}</tbody>;
};

export default TableBody;
