import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';

const StyledTableHeader = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.white,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 1,
    },
  }));
export default StyledTableHeader