
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import ProductList from './Components/ProductList';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';


import QuestionDetailsPage from "./app/productConfig/QuestionDetailsPage";
import DashboardPage from "./app/dashboard/DashboardPage";
import DashboardProjectPage from "./app/dashboard/DashboardProjectPage"
import { Card } from 'reactstrap';

function App() {
  return (
    <Card>
      <ToastContainer closeButton={false} position="bottom-right" />
      <Router>
        <Routes>
          <Route path='/productconfig' element={<QuestionDetailsPage/>} />
          <Route path='/dashboard' element={<DashboardProjectPage/>} />
          {/* <Route path='/dashboardnew' element={<DashboardProjectPage/>} /> */}
        </Routes>
      </Router>
      {/* <div ><label style={{ marginLeft: "100px", fontWeight: 'normal' }}>© Sonance</label>&nbsp;{new Date().getFullYear()}</div> */}
      
    </Card>

  );
}

export default App;
