import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Spinner from '../common/Spinner';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';
import TitleHeader from '../common/TitleHeader';
import RowForTable from './components/TableRow';
import Paper from '@mui/material/Paper';
import StyledTableHeader from './components/TableHeaderStyle';
import DashBoardSearch from './components/DashBoardSearch';

export default function DashboardProjectPage(props) {
    const navigate = useNavigate();
    // store page loading state
    const [isLoading, setIsLoading] = useState(false);
    // store querystring params
    const [queryParams, setQueryParams] = useState(undefined);
    // const [sessionList, setSessionList] = useRef([]);
    const [showMyProductPrice, setShowMyProductPrice] = useState(false);
    const [showMSRPPrice, setShowMSRPPrice] = useState(true);
    const [showWholeSalePrice, setShowWholeSalePrice] = useState(true);
 

    const sessionListRef = useRef(null);
    const [filteredData, setFilteredData] = useState([]);

    const searchParam = useLocation().search;
    const encryptedCustomerNo = new URLSearchParams(searchParam).get('c');
    const encryptedEmail    = new URLSearchParams(searchParam).get('e');
    const encryptedPricing = new URLSearchParams(searchParam).get('pr');
    useEffect(() => {
        const api_url = process.env.REACT_APP_SERVER_URL;
        const getSessionsList = async () => {
            try {
                setIsLoading(true); 
                let promises = [];

                const payload = {
                    CustomerNo: new URLSearchParams(searchParam).get('c'),
                    Email: new URLSearchParams(searchParam).get('e'),
                    Currency: new URLSearchParams(searchParam).get('curr'),
                    ConfigID: new URLSearchParams(searchParam).get('cID') || new URLSearchParams(searchParam).get('cId') || new URLSearchParams(searchParam).get('cid'),
                    CloneID: new URLSearchParams(searchParam).get('cn'),
                    ProductId: new URLSearchParams(searchParam).get('p'),
                    Pricing: new URLSearchParams(searchParam).get('pr'),
                    CanAddToCart: new URLSearchParams(searchParam).get('a')
                };


               //decrypt query params
               promises.push(
                    axios.post(`${api_url}Product/Decrypt`, payload, {
                    headers: { 'Content-Type': 'application/json' },
                    })
                ); 
 
               promises.push(axios.get(`${api_url}Product/GetConfigurations?c=${encryptedCustomerNo}&e=${encryptedEmail}`));
               Promise.all(promises).then((responses) => {
                // set decrypted query params
                    const queryParamData = responses[0].data;
                    const defaultCurrency = queryParamData.currency ?? 'USD'; // default 'USD'
                    const defaultPricing = queryParamData.pricing ?? '1,1,1'; // default 'See all pricing {MSRP,Wholesale,MyPrice}'
                    const canAddToCart = queryParamData.canAddToCart?.toLowerCase() === "false" ? false : true
                    const paramsData = {
                        ...queryParamData,
                        currency: defaultCurrency,
                        pricing: defaultPricing,
                        canAddToCart: canAddToCart
                    };
 
                   
                    setQueryParams(paramsData);
                    const data = responses[1].data;
                    
                    sessionListRef.current = data;
                   
                    setFilteredData(data);
                    checkPricingPermissions(defaultPricing);
                    setIsLoading(false);
                });
                
            } catch (error) {
                setIsLoading(false);
                //console.log('error fetching session list', error);
            }
        };
        getSessionsList();
    }, [encryptedCustomerNo]);

    const editRecord = async (config) => {
        try {
            const payload = {
                ConfigID: config.idConfig.toString(),
            };
            setIsLoading(true);

            const response = await axios.post(
                process.env.REACT_APP_SERVER_URL + 'Product/Encrypt',
                payload,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            const data = response.data;
            const params = [
                ['cID', data.configID],
                ['c', encryptedCustomerNo],
            ];

            navigate({
                pathname: '/productconfig',
                search: `?${createSearchParams(params)}`,
            });
        } catch (error) {
            console.log('error fetching the product configuration', error);
            toast.error('Error fetching the product configuration.');
        } finally {
            setIsLoading(false);
        }
    };

    const cloneRecord = async (config) => {
        try {
            const payload = {
                CloneID: config.idConfig.toString(),
            };
            setIsLoading(true);

            const response = await axios.post(
                process.env.REACT_APP_SERVER_URL + 'Product/Encrypt',
                payload,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            const data = response.data;
            const params = [
                ['cn', data.cloneID],
                ['c', encryptedCustomerNo],
            ];

            navigate({
                pathname: '/productconfig',
                search: `?${createSearchParams(params)}`,
            });
        } catch (error) {
            console.log('error fetching the product configuration', error);
            toast.error('Error fetching the product configuration.');
        } finally {
            setIsLoading(false);
        }
    };

    const updateProjectStatus = (config, newValue) => {
        try {
            const payload = {
                Id: config.id,
                Status: newValue,
            };
            const apiResponse = axios.post(
                process.env.REACT_APP_SERVER_URL +
                    'Product/UpdateProjectStatus',
                payload,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            toast.success('Project updated successfully.');
        } catch (error) {
            console.log('error saving the project', error);
            toast.error('Error saving the project.');
        }
    };

    const updateStatus = (config, newValue) => {
        try {
            const payload = {
                idConfig: config.idConfig,
                Status: newValue,
            };
            const apiResponse = axios.post(
                process.env.REACT_APP_SERVER_URL + 'Product/UpdateConfig',
                payload,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            toast.success('Configuration saved successfully.');
        } catch (error) {
            console.log('error saving the product configuration', error);
            toast.error('Error saving the product configuration.');
        }
    };

    const searchList = async (configSearch) => {
        try {
            setIsLoading(true);

            const payload = {
                CustomerNumber: encryptedCustomerNo,
                Email: encryptedEmail,
                ProjectName: configSearch.projectName,
                Title: configSearch.title,
                SKU: configSearch.sku,
                FromDate: configSearch.startDate,
                ToDate: configSearch.endDate,
                Status: configSearch.status,
                CreatedBy: configSearch.user
            };
            const apiResponse = await axios.post(
                process.env.REACT_APP_SERVER_URL +
                    'Product/SearchConfigurations',
                payload,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            setFilteredData(apiResponse.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log('Error searching the list', error);
            toast.error('Error searching the list.');
        }
    };

    const clearSearchList = () => {
        try {
            setFilteredData(sessionListRef.current);
        } catch (error) {
            console.log('error searching the list', error);
            toast.error('Error searching the list.');
        }
    };

    function checkPricingPermissions(pricing)
    {

        if(pricing && pricing.length > 0)
        {
            var pf = pricing.split(",");
            if(pf.length === 3)    
            {
                setShowMyProductPrice(pf[2] === "1");
                setShowWholeSalePrice(pf[1] === "1");
                setShowMSRPPrice(pf[0] === "1");
            }
        }
        else{
            setShowMyProductPrice(true);
            setShowWholeSalePrice(true);
            setShowMSRPPrice(true);
        }
               
    }

    return (
        <div>
            <Card>
                
                    <TitleHeader show={false}></TitleHeader>
               
                <CardBody
                    style={{ minHeight: '85vh', backgroundColor: '#e9ecee' }}
                >
                    {isLoading && <Spinner />}
                    
                    <Row>
                        <Col>
                            <Card className='dashboardForm'>
                                <CardBody
                                    style={{
                                        backgroundColor: '#fffff',
                                        boxShadow:
                                            'rgba(0, 0, 0, 0.1) 0px 12px 24px 0px ',
                                    }}
                                >
                                    <Row>
                                        <DashBoardSearch
                                            searchList={searchList}
                                            clearSearchList={clearSearchList}
                                        ></DashBoardSearch>
                                    </Row>
                                    <Row>
                                        <TableContainer component={Paper}>
                                            <Table aria-label='collapsible table'>
                                                <TableHead>
                                                    <StyledTableHeader>
                                                        <TableCell sx={{"width": "30%"}} colSpan={2}>Projects</TableCell>
                                                        <TableCell align='center' sx={{"width": "5%"}} >
                                                           Total Qty
                                                        </TableCell>
                                                        <TableCell align='center'  sx={{"width": "20%"}}  >
                                                            Project Total
                                                        </TableCell>
                                                        <TableCell  sx={{"width": "5%"}}>
                                                          Your Project Status
                                                        </TableCell>
                                                        <TableCell  sx={{"width": "15%"}}>
                                                            Project Created
                                                        </TableCell>
                                                        <TableCell>
                                                             
                                                        </TableCell> 
                                                    </StyledTableHeader>
                                                </TableHead>
                                                {filteredData && (
                                                    <TableBody>
                                                        {filteredData.map(
                                                            (row) => (
                                                                <RowForTable
                                                                    updateStatus={
                                                                        updateStatus
                                                                    }
                                                                    editRecord={
                                                                        editRecord
                                                                    }
                                                                    cloneRecord={
                                                                        cloneRecord
                                                                    }
                                                                    updateProjectStatus={
                                                                        updateProjectStatus
                                                                    }
                                                                    key={row.id}
                                                                    row={row}
                                                                    user={queryParams.email}
                                                                    showMSRP = {showMSRPPrice}
                                                                    showWholeSale= {showWholeSalePrice}
                                                                    showMyPrice={showMyProductPrice}
                                                                    canAddToCart={queryParams.canAddToCart}
                                                                />
                                                            )
                                                        )}
                                                    </TableBody>
                                                )}
                                            </Table>
                                        </TableContainer>
                                    </Row>
                                    <br />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
}
