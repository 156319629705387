import React, { useState, useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import {  
    Row,
    Col,
    Button,
    Tooltip
} from 'reactstrap';
import axios from 'axios';
import StyledTableRow from './TableRowStyle';

const RowForTable = (props) => {
    const api_url = process.env.REACT_APP_SERVER_URL;
    const { row, showMSRP, showWholeSale, showMyPrice, user, canAddToCart } = props;
    const [open, setOpen] = useState(false);
    const [totalQty, setTotalQty] = useState(0);
    const [totalPrice, setTotalPrice] = useState("0.00");
    const [tbdPrice ,setTBDPrice] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    useEffect(() => {
            //calculate visible qty and total price for current user. may be different than the overall qty and price for this project due to the private/public restriction
            let tempQty = 0;
            let tempTtl = 0.00;
            let tbdFound  = false;
            row.sessionConfigurations.map(pc => {
             
                if(!tbdFound && pc.netPrice ==="TBD")
                    tbdFound = true;
                if(user ===pc.createdBy || !pc.isPrivate)
                {
                    tempQty += pc.qty;
                    if(!tbdFound)
                        tempTtl += parseFloat(pc.netPrice);
                }
           });
           setTotalQty(tempQty);
           setTotalPrice( tbdFound ? "TBD" : tempTtl.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
           setTBDPrice(tbdFound);
            
    }, [row]);

    const handleAddProjectToCartClick = async (project) =>  {
        let configIdsArray =[];
        //loop through configuration Id
         project.sessionConfigurations.map(config => {
            configIdsArray.push(config.idConfig);
        });
         console.log("configIdsArray",configIdsArray);
         let url = `${api_url}Product/GetProductConfigurationByIds/Clean`;
         let api_method = axios.post;
     
         const apiResponse = await api_method(url, configIdsArray, {
            headers: { 'Content-Type': 'application/json' },
        });

       
        if(apiResponse.data)
        {
            let optiConfigs = [];
            apiResponse.data.map(cleanConfig => {
                var optiConfig = cleanConfig.sessionConfigurationDetails.map(({configDetailVariable,  answerText
                }) => [configDetailVariable, answerText]);

                optiConfigs.push({
                    "ConfigurationId" : cleanConfig.idConfig,
                    "ConfigurationOptions": optiConfig,
                    "Product_Id":cleanConfig.product.productId,
                    "Quantity":cleanConfig.qty 
                });
            });
            var postMessage =  {
                "Status": "Complete",
                Configurations:optiConfigs
            }
            console.log("postMessage",postMessage);
            window.parent.postMessage(postMessage, "*");
        }
      
        
    }

    const handleAddToCartClick = async (configId) =>  {
        let url = `${api_url}Product/GetProductConfigurationById/${configId}/Clean`;
        let api_method = axios.get;

            const apiResponse = await api_method(url,  {
                headers: { 'Content-Type': 'application/json' },
            });

            var optiConfig = apiResponse.data.sessionConfigurationDetails.filter(sd => sd.configDetailVariable !== "IV_CUST_CURRNCY" && sd.configDetailVariable !== "WAREHOUSE" && sd.configDetailVariable !== "PRODUCT_ID")
            .map(s => [s.configDetailVariable, s.answerText]);
  
            let optiConfigs = [];
            optiConfigs.push({
                "ConfigurationId" : configId,
                "ConfigurationOptions": optiConfig,
                "Product_Id": apiResponse.data.product.productId,
                "Quantity": apiResponse.data.qty 
            });
            var postMessage =  {
                "Status": "Complete",
                Configurations:optiConfigs
            }
            console.log("postMessage",postMessage);
            window.parent.postMessage(postMessage, "*");
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={{"width": "8%"}} >
                    <IconButton
                        aria-label='expand row'
                        size='small'
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell component='th' scope='row' sx={{"width": "25%"}} >
                    <span className='thProjectTitle'> {row.projectName}</span>
                </TableCell>
                <TableCell align='center' sx={{"width": "5%"}} >{totalQty}</TableCell>
                <TableCell align='center' sx={{"width": "20%"}}>  
                    <span>{totalPrice}</span>
                   
                    {
                        tbdPrice && ( 
                            <>
                             <span id={`projectprice_${row.id}`} className='tooltipIcon'> 
                            <i className="info">i</i>
                            </span>
                        <Tooltip
                            placement="right"
                            isOpen={tooltipOpen}
                            target={`projectprice_${row.id}`}
                            toggle={toggleTooltip}
                            >
                            Your custom configuration request has been emailed to the James Engineering Team for review, pricing and approval.
                            </Tooltip>
                            </>
                           )
                    }
               
                </TableCell>
                <TableCell sx={{"width": "5%"}} >
                    {' '}
                    <input
                        type='text'
                        defaultValue={row.status}
                        onBlur={(e) => {
                            props.updateProjectStatus(row, e.target.value);
                        }}
                    />
                </TableCell>
                <TableCell sx={{"width": "15%"}} >{new Date(row.dateCreated).toLocaleDateString()}</TableCell>
               <TableCell sx={{"width": "35%"}} >
                    {
                        !tbdPrice && canAddToCart && <Button
                                        className='btn-custom mt-2'
                                        color='primary'
                                        onClick={(e) =>
                                            handleAddProjectToCartClick(row)
                                        }
                                        >
                                        Add Project To Cart
                                        </Button>
                    }     
               </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={7}
                >
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size='small' aria-label='purchases'>
                                <TableHead>
                                    <StyledTableRow>
                                        <TableCell sx={{"width": "12%"}} >User</TableCell>
                                        <TableCell sx={{"width": "10%"}} >Model</TableCell>
                                        <TableCell align='left' sx={{"width": "12%"}} >Title</TableCell>
                                        <TableCell align='center' sx={{"width": "12%"}} >Your Status</TableCell>
                                        <TableCell align='center' sx={{"width": "8%"}}  >Qty</TableCell>
                                        <TableCell align='center' sx={{"width": "16%"}} >Price</TableCell>
                                        <TableCell align='center' sx={{"width": "8%"}} >Net Price</TableCell>
                                       
                                        <TableCell  align='center' sx={{"width": "22%"}} >
                                            Action
                                        </TableCell>
                                         
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {row.sessionConfigurations.map(
                                        (historyRow) => (
                                            <StyledTableRow
                                                key={historyRow.idConfig}
                                            >
                                                <TableCell>
                                                    {historyRow.createdBy}
                                                </TableCell>
                                                <TableCell
                                                    onClick={(e) =>
                                                        {
                                                            if(user === historyRow.createdBy)
                                                                props.editRecord(
                                                                    historyRow
                                                                );
                                                            else
                                                            props.cloneRecord(
                                                                historyRow
                                                            );  
                                                        }
                                                       
                                                    }
                                                    component='th'
                                                    scope='row'
                                                >
                                                    <u
                                                        style={{
                                                            color: 'blue',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {' '}
                                                        {historyRow.baseModel}
                                                    </u>
                                                </TableCell>
                                                <TableCell>
                                                    {historyRow.title}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <input
                                                        type='text'
                                                        defaultValue={
                                                            historyRow.status
                                                        }
                                                        onBlur={(e) => {
                                                            props.updateStatus(
                                                                historyRow,
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {historyRow.qty}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        showMSRP && (
                                                        <Row>
                                                            <Col md='6'  align="right">
                                                            <b
                                                            style={{
                                                                color: 'rgb(0, 0, 0)',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            MSRP:
                                                            </b>
                                                            </Col>
                                                            <Col md='5' align='left'>
                                                                {`${parseFloat(historyRow.msrp).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}   
                                                            </Col>
                                                        </Row>

                                                        )
                                                    }
                                                    {
                                                        showWholeSale && (
                                                        <Row>
                                                            <Col md='6' align="right">
                                                            <b
                                                            style={{
                                                                color: 'rgb(0, 0, 0)',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            WHOLESALE:
                                                            </b>
                                                            </Col>
                                                            <Col md='5' align='left'>
                                                                {`${parseFloat(historyRow.wholeSalePrice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}                                                               
                                                            </Col>
                                                        </Row>

                                                        )
                                                    }

                                                    {
                                                        showMyPrice && (
                                                        <Row>
                                                              <Col md='6' align="right">
                                                            <b
                                                            style={{
                                                                color: 'rgb(24, 187, 156)',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            MY PRICE: 
                                                            </b>
                                                            </Col>
                                                            <Col md='5' align='left'>
                                                            {`${parseFloat(historyRow.basePrice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}  
                                                            </Col>
                                                        </Row>

                                                        )
                                                    }
                                                          <Row>
                                                          <Col md='6' align="right">
                                                            <b
                                                            style={{
                                                                color: '#007DAB',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            
                                                            CONFIGURED: 
                                                            </b>
                                                            </Col>
                                                            <Col md='5' align='left'>
                                                                {
                                                                    historyRow.price !== "TBD" ?
                                                                    `${parseFloat(historyRow.price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                                                                    :
                                                                    historyRow.price
                                                                }
                                                               
                                                            </Col>
                                                        </Row>

                                                    
                                                    
                                                   
                                                </TableCell>
                                                <TableCell align='center'>
                                                {
                                                                    historyRow.netPrice !== "TBD" ?
                                                                    `${parseFloat(historyRow.netPrice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                                                                    :
                                                                    historyRow.netPrice
                                                }
                                                
                                                
                                                         
                                                </TableCell>
                                                
                                                <TableCell>
                                                    {
                                                        user === historyRow.createdBy && (
                                                            <Button
                                                            title='Edit'
                                                            className='btn-custom ml-2'
                                                            onClick={(e) =>
                                                                props.editRecord(
                                                                    historyRow
                                                                )
                                                            }
                                                        >
                                                            <span id='boot-icon'></span>{' '}
                                                            Edit
                                                        </Button>
                                                        )
                                                    }
                                                    <Button
                                                        title='Copy'
                                                        className='btn-clone ml-2'
                                                        onClick={(e) =>
                                                            props.cloneRecord(
                                                                historyRow
                                                            )
                                                        }
                                                    >
                                                        <span id='boot-icon'></span>{' '}
                                                        Copy
                                                    </Button>
                                                    <Button
                                                            className='btn-custom ml-2'
                                                            color='primary'
                                                            onClick={(e) =>
                                                                handleAddToCartClick(historyRow.idConfig)
                                                            }
                                                        >
                                                            Add To Cart
                                                    </Button>
                                                </TableCell>
                                            </StyledTableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};
export default RowForTable;
