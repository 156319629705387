import React from 'react';
import TableHeader from './TableHeader';
import TableBody from './TableBody';

class Table extends React.Component{
  render(){
    const {sessionData, updateStatus, editRecord, cloneRecord} = this.props;
    
    return(
      <table className='table table-borderd table-responsive table-striped dashboard'>
        <TableHeader/>
        <TableBody sessionData={sessionData} updateStatus={updateStatus} editRecord={editRecord} cloneRecord={cloneRecord}/>
      </table>

    )
  }
}

export default Table;