import React from 'react';
import { toast } from 'react-toastify';
import { Button, Input, InputGroup, InputGroupText } from 'reactstrap';

const AddtoCartButton = ({ qty, onChangeQty, canAddToCart, onSave, onSendEmail }) => {
    const handleClick = async (e) => {
        if (canAddToCart) {
            const result = await onSave(e);
            if (result === true) toast.success('Product added to cart');
        } else {
            toast.error(`This product cannot be added to the cart at this time. \n
            Your Inside Sales Representative will get in touch with you to continue with this order.`);
        }
    };

    const handlReviewButtonClick = async (e) => {
        const result = await onSendEmail(e);
        if (result === true)    toast.success(`Your Inside Sales Representative will get in touch with you to continue with this order.`
        );
    };

    return (
        <>
            <InputGroup>
                <InputGroupText>Qty</InputGroupText>
                <Input
                    placeholder='Qty'
                    type='number'
                    value={qty}
                    onChange={onChangeQty}
                    min='1'
                />
                {canAddToCart && (
                    <Button
                        className='btn-custom mt-2'
                        color='primary'
                        onClick={handleClick}
                        id="addToCart"
                    >
                        Add To Cart
                    </Button>
                )}
                {!canAddToCart && (
                    <Button
                        className='btn-custom mt-2'
                        color='primary'
                        onClick={handlReviewButtonClick}
                    >
                        Send for review
                    </Button>
                )}
            </InputGroup>
        </>
    );
};

export default AddtoCartButton;
