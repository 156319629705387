import React, { useState } from 'react';
import { FormGroup, Row, Col, Label, Button, Alert } from 'reactstrap';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { toast } from 'react-toastify';

const DashBoardSearch = (props) => {
    const [projectName, setProject] = useState();
    const [title, setTitle] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [sku, setSku] = useState();
    const [status, setStatus] = useState();
    const [user, setUser] = useState();

    const handleClick = () => {
        setProject(null);
        setTitle(null);
        setSku(null);
        setStartDate(null);
        setEndDate(null);
        setStatus(null);
        setUser(null);
        props.clearSearchList();
    };

    const handleEnterKeyDown = (e) =>{
        if (e.keyCode === 13) {
            handleSearchClick();
        }
    }

    const handleSearchClick = () => {
        if (endDate < startDate) {
            toast.error('End date should be greater than Start date');
            return;
        } else {
            props.searchList({
                projectName: projectName,
                title: title,
                sku: sku,
                startDate: startDate,
                endDate: endDate, 
                status: status,
                user: user
            });
        }
    };

    return (
        <div>
            <Row>
                <Col md='2'>
                    <FormGroup>
                        <Label>
                            <span>Project</span>
                        </Label>
                        <input
                            type='text'
                            name='projectName'
                            value={projectName || ''}
                            className='form-control'
                            placeholder='Enter Project'
                            onChange={(event) => setProject(event.target.value)}
                            onKeyDown={handleEnterKeyDown}
                            onBlur={handleSearchClick}
                        />
                    </FormGroup>
                </Col>
                <Col md='2'>
                    <FormGroup>
                        <Label>
                            <span>Project Zone/Location</span>
                        </Label>
                        <input
                            type='text'
                            name='title'
                            value={title || ''}
                            className='form-control'
                            placeholder='Enter Title'
                            onChange={(event) => setTitle(event.target.value)}
                            onKeyDown={handleEnterKeyDown}
                            onBlur={handleSearchClick}
                        />
                    </FormGroup>
                </Col>
                <Col md='2'>
                    <FormGroup>
                        <Label>
                            <span>Date Created From</span>
                        </Label>
                        <LocalizationProvider dateAdapter={AdapterDayjs} className='btn-custom'>
                            <DatePicker  className='btn-custom'
                                value={startDate || null}
                                onChange={(newValue) => setStartDate(newValue)}
                                onBlur={handleSearchClick}
                                onKeyDown={handleEnterKeyDown}
                            />
                        </LocalizationProvider>
                    </FormGroup>
                </Col>
                <Col md='2'>
                    <FormGroup>
                        <Label>
                            <span>Date Created To</span>
                        </Label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                className='btn-custom custom-noborder'
                                value={endDate || null}
                                onChange={(newValue) => setEndDate(newValue)}
                                onBlur={handleSearchClick}
                                onKeyDown={handleEnterKeyDown}
                            />
                        </LocalizationProvider>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
            <Col md='2'>
                    <FormGroup>
                        <Label>
                            <span>User</span>
                        </Label>
                        <input
                            type='text'
                            name='title'
                            value={user || ''}
                            className='form-control'
                            placeholder='User'
                            onChange={(event) => setUser(event.target.value)}
                            onBlur={handleSearchClick}
                            onKeyDown={handleEnterKeyDown}
                        />
                    </FormGroup>
                </Col>
                <Col md='2'>
                    <FormGroup>
                        <Label>
                            <span>SKU</span>
                        </Label>
                        <input
                            type='text'
                            name='title'
                            value={sku || ''}
                            className='form-control'
                            placeholder='SKU'
                            onChange={(event) => setSku(event.target.value)}
                            onBlur={handleSearchClick}
                            onKeyDown={handleEnterKeyDown}
                        />
                    </FormGroup>
                </Col>
                <Col md='2'>
                    <FormGroup>
                        <Label>
                            <span>Status</span>
                        </Label>
                        <input
                            type='text'
                            name='title'
                            value={status || ''}
                            className='form-control'
                            placeholder='Status'
                            onChange={(event) => setStatus(event.target.value)}
                            onBlur={handleSearchClick}
                            onKeyDown={handleEnterKeyDown}
                        />
                    </FormGroup>
                </Col>
                <Col md='3'></Col>
                <Col md='2'>
                    {/* <SearchIcon
                        title='Edit'
                        className='btn-custom bi bi-pencil'
                        onClick={handleSearchClick}
                        style={{ fontSize: 50, marginTop: '43px' }}
                    /> */}

                    <Button
                        title='Clear Filters'
                        className='btn-clone'
                        onClick={handleClick}
                        style={{ marginTop: '43px' }}
                    >
                        {' '}
                        CLEAR FILTERS
                    </Button>
                    {/* <ClearIcon
                        title='Edit'
                        className='btn-custom bi bi-pencil'
                        onClick={handleClick}
                        style={{ fontSize: 50, marginTop: '43px' }}
                    /> */}
                </Col>
            </Row>
            <Row>
                
            </Row>
        </div>
    );
};
export default DashBoardSearch;
