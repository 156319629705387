import React from 'react';

const TableHeader = () =>{
  return(
    <thead>
      <tr>
      <th>Product</th>
      <th>Project</th>
      <th>Title</th>
      <th>Qty</th>
      <th>Price</th>
      <th>Status</th>
      <th>Action</th>
     </tr>
      
    </thead>
  )
}

export default TableHeader;